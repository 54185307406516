//import logo from './logo.svg';
import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './bootstrap5/css/bootstrap.min.css';
import './App.css';
import Loader from './com/loader';



const MenuComponent = React.lazy(

() => {//import('./com/home')

return new Promise(resolve => {
    setTimeout(() => resolve(import("./com/header_menu")), 2000);
  });

}

);


function App() {
  return (
    <div className="App">
    <Router>
    <Suspense fallback=<Loader/> >      
      <MenuComponent/> 
      </Suspense>
    </Router>  
    </div>
  );
}

export default App;
